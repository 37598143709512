<div class="op-modal--portal  job-status--modal -wide-fixed">
  <div class="op-modal--modal-container"
       tabindex="0">
    <div class="op-modal--modal-header">
      <h3 [textContent]="title"></h3>
      <a class="op-modal--modal-close-button">
        <i
          class="icon-close"
          (click)="closeMe($event)"
          [attr.title]="text.closePopup">
        </i>
      </a>
    </div>

    <div>
      <div class="loading-indicator--location"
           data-indicator-name="modal">
        <div class="status-icon-wrapper" *ngIf="!isLoading && statusIcon">
          <span [ngClass]="statusIcon" class="icon-big"></span>
        </div>
      </div>
      <div>
        <span class="job-status--modal-main-message" [textContent]="message"></span>
        <span [hidden]="!downloadHref">
          {{ text.download_starts }}
          <a #downloadLink
             download
             target="_blank"
             [textContent]="text.click_to_download"
             [attr.href]="downloadHref">
          </a>
        </span>
        <ng-container *ngIf="payload?.errors">
          <h3 class="job-status--modal-additional-errors" [textContent]="text.errors"></h3>
          <ul *ngFor="let error of payload.errors">
            <li [textContent]="error"></li>
          </ul>

          <p *ngIf="payload?.redirect">
            <span [textContent]="text.redirect_errors"></span>
            <a [textContent]="text.redirect_link"
               [attr.href]="payload.redirect">
            </a>
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
